import React from "react";
import "../css/AdminControlPanel.css"; // Подключаем файл CSS для стилизации

const AdminControlPanel = () => {
  return (
    <div className="admin-control-panel">
      <h2>Управление ключами</h2>
      <div className="link-group">
        <a href="/generate-key">Generate Key</a>
        <a href="/show-users">Show Users</a>
        <a href="/reset-hwid">Reset HWID</a>
        <a href="/find-user">Find User</a>
        <a href="/generate-key">Generate Key</a>
      </div>
    </div>
  );
};

export default AdminControlPanel;
