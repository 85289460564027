import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addProduct,
  updateProduct,
  getProduct,
} from "../services/productService";
import { getCategories } from "../services/categoryService";
import styles from "../css/AdminProductForm.module.css"; // Импорт CSS Module

const AdminProductForm = () => {
  const [product, setProduct] = useState({
    name: "",
    category: "",
    images: [],
    systemRequirements: "",
    functionality: "",
    video: "",
    tags: "",
    purchaseOptions: [],
    features: [],
  });
  const [categories, setCategories] = useState([]);
  const [newOption, setNewOption] = useState({ days: "", link: "" });
  const [newFeature, setNewFeature] = useState({ title: "", description: "" });
  const [editingOptionIndex, setEditingOptionIndex] = useState(null);
  const [editingFeatureIndex, setEditingFeatureIndex] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchProduct = async () => {
      if (id) {
        try {
          const fetchedProduct = await getProduct(id);
          setProduct(fetchedProduct);
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      }
    };

    fetchCategories();
    fetchProduct();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setNewOption((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFeatureChange = (e) => {
    const { name, value } = e.target;
    setNewFeature((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addOption = () => {
    setProduct((prevState) => ({
      ...prevState,
      purchaseOptions: [...prevState.purchaseOptions, newOption],
    }));
    setNewOption({ days: "", link: "" });
  };

  const updateOption = () => {
    const updatedOptions = product.purchaseOptions.map((option, index) =>
      index === editingOptionIndex ? newOption : option
    );
    setProduct((prevState) => ({
      ...prevState,
      purchaseOptions: updatedOptions,
    }));
    setNewOption({ days: "", link: "" });
    setEditingOptionIndex(null);
  };

  const addFeature = () => {
    setProduct((prevState) => ({
      ...prevState,
      features: [...prevState.features, newFeature],
    }));
    setNewFeature({ title: "", description: "" });
  };

  const updateFeature = () => {
    const updatedFeatures = product.features.map((feature, index) =>
      index === editingFeatureIndex ? newFeature : feature
    );
    setProduct((prevState) => ({
      ...prevState,
      features: updatedFeatures,
    }));
    setNewFeature({ title: "", description: "" });
    setEditingFeatureIndex(null);
  };

  const editOption = (index) => {
    setNewOption(product.purchaseOptions[index]);
    setEditingOptionIndex(index);
  };

  const editFeature = (index) => {
    setNewFeature(product.features[index]);
    setEditingFeatureIndex(index);
  };

  const deleteOption = (index) => {
    const updatedOptions = product.purchaseOptions.filter(
      (option, i) => i !== index
    );
    setProduct((prevState) => ({
      ...prevState,
      purchaseOptions: updatedOptions,
    }));
  };

  const deleteFeature = (index) => {
    const updatedFeatures = product.features.filter(
      (feature, i) => i !== index
    );
    setProduct((prevState) => ({
      ...prevState,
      features: updatedFeatures,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedTags = product.tags;
      if (typeof updatedTags === "string") {
        updatedTags = updatedTags.split(" ");
      }

      const updatedProduct = {
        ...product,
        tags: updatedTags,
      };

      if (id) {
        await updateProduct(id, updatedProduct);
      } else {
        await addProduct(updatedProduct);
      }
      navigate("/admin/products");
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  return (
    <div className={styles.adminProductFormContainer}>
      <h1 className={styles.adminProductFormHeading}>
        {id ? "Edit Product" : "Add Product"}
      </h1>
      <form onSubmit={handleSubmit} className={styles.adminProductForm}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Category:</label>
          <select
            name="category"
            value={product.category}
            onChange={handleChange}
            required
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Images:</label>
          <input
            type="text"
            name="images"
            value={product.images.join(", ")}
            onChange={(e) =>
              setProduct((prevState) => ({
                ...prevState,
                images: e.target.value.split(",").map((img) => img.trim()),
              }))
            }
          />
        </div>
        <div>
          <label>System Requirements:</label>
          <textarea
            name="systemRequirements"
            value={product.systemRequirements}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Functionality:</label>
          <textarea
            name="functionality"
            value={product.functionality}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Video:</label>
          <input
            type="text"
            name="video"
            value={product.video}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Tags (separated by spaces):</label>
          <input
            type="text"
            name="tags"
            value={
              Array.isArray(product.tags)
                ? product.tags.join(" ")
                : product.tags
            }
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Purchase Options:</label>
          {product.purchaseOptions.map((option, index) => (
            <div key={index} className={styles.adminProductOption}>
              {option.days} days - <a href={option.link}>Link</a>
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={() => editOption(index)}
              >
                Edit
              </button>
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={() => deleteOption(index)}
              >
                Delete
              </button>
            </div>
          ))}
          <div>
            <input
              type="text"
              name="days"
              value={newOption.days}
              placeholder="Days"
              onChange={handleOptionChange}
            />
            <input
              type="text"
              name="link"
              value={newOption.link}
              placeholder="Link"
              onChange={handleOptionChange}
            />
            {editingOptionIndex !== null ? (
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={updateOption}
              >
                Update Option
              </button>
            ) : (
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={addOption}
              >
                Add Option
              </button>
            )}
          </div>
        </div>
        <div>
          <label>Features:</label>
          {product.features.map((feature, index) => (
            <div key={index} className={styles.adminProductFeature}>
              <strong>{feature.title}</strong>: {feature.description}
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={() => editFeature(index)}
              >
                Edit
              </button>
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={() => deleteFeature(index)}
              >
                Delete
              </button>
            </div>
          ))}
          <div>
            <input
              type="text"
              name="title"
              value={newFeature.title}
              placeholder="Title"
              onChange={handleFeatureChange}
            />
            <textarea
              name="description"
              value={newFeature.description}
              placeholder="Description"
              onChange={handleFeatureChange}
              rows="4"
              cols="50"
            />
            {editingFeatureIndex !== null ? (
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={updateFeature}
              >
                Update Feature
              </button>
            ) : (
              <button
                type="button"
                className={styles.adminProductFormButton}
                onClick={addFeature}
              >
                Add Feature
              </button>
            )}
          </div>
        </div>
        <button type="submit" className={styles.adminProductFormButton}>
          Save
        </button>
      </form>
    </div>
  );
};

export default AdminProductForm;
