// src/components/ShowUsers.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/ShowUsers.css"; // Импортируем стили

const ShowUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin`, // Используем полный URL API
        {
          command: "show",
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_API_TOKEN, // Ваш API токен
            "Content-Type": "application/json",
          },
        }
      );
      if (Array.isArray(res.data)) {
        setUsers(res.data);
      } else {
        setError("Invalid data format: expected an array.");
      }
    } catch (error) {
      console.error(error);
      setError("Error fetching users.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="show-users-container">
      <h2>Users List</h2>

      {loading && (
        <div className="loading">
          <span>Loading...</span>
        </div>
      )}

      {error && <div className="error-message">{error}</div>}

      {!loading && !error && (
        <table className="users-table">
          <thead>
            <tr>
              <th>License</th>
              <th>Username</th>
              <th>Password</th>
              <th>HWID</th>
              <th>Last Login</th>
              <th>Last IP</th>
              <th>Expiry</th>
              <th>Rank</th>
              <th>Last Reset</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.License}</td>
                <td>{user.Username}</td>
                <td>{user.Password}</td>
                <td>{user.HWID}</td>
                <td>{user.LastLogin}</td>
                <td>{user.LastIP}</td>
                <td>{user.Expiry}</td>
                <td>{user.Rank}</td>
                <td>{user.LastReset}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ShowUsers;
