import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../css/AdminPanel.module.css"; // Импорт CSS Module

const AdminPanel = ({ token }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className={styles.adminPanelContainer}>
      <h2 className={styles.adminPanelTitle}>Admin Panel</h2>
      <nav className={styles.adminNav}>
        <ul className={styles.adminNavList}>
          <li>
            <Link to="/admin/products" className={styles.adminNavLink}>
              Manage Products
            </Link>
          </li>
          <li>
            <Link to="/admin/categories" className={styles.adminNavLink}>
              Manage Categories
            </Link>
          </li>
        </ul>
      </nav>
      <button className={styles.logoutButton} onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default AdminPanel;
