import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCategory,
  updateCategory,
  getCategory,
} from "../services/categoryService";
import styles from "../css/AdminCategoryForm.module.css"; // Импорт CSS Module

const AdminCategoryForm = () => {
  const [category, setCategory] = useState({ name: "", imageLink: "" });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchCategory = async () => {
        try {
          const fetchedCategory = await getCategory(id);
          setCategory(fetchedCategory);
        } catch (error) {
          console.error("Error fetching category:", error);
        }
      };

      fetchCategory();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateCategory(id, category);
      } else {
        await addCategory(category);
      }
      navigate("/admin/categories");
    } catch (error) {
      console.error("Error saving category:", error);
    }
  };

  return (
    <div className="block adminInput">
      <h1 className={styles.adminCategoryFormHeading}>
        {id ? "Edit Category" : "Add Category"}
      </h1>
      <form onSubmit={handleSubmit} className={styles.adminCategoryForm}>
        <label>Name:</label>
        <div className="inputContainer">
          <input
            type="text"
            name="name"
            value={category.name}
            onChange={handleChange}
            required
            className="input"
          />
        </div>
        <label>Image Link:</label>
        <div className="inputContainer">
          <input
            type="text"
            name="imageLink"
            value={category.imageLink}
            onChange={handleChange}
            required
            className="input"
          />
        </div>
        <button type="submit" className="linkAccent">
          Save
        </button>
      </form>
    </div>
  );
};

export default AdminCategoryForm;
