import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getProducts,
  getCategoryByProductId,
  deleteProduct,
} from "../services/productService"; // Импортируем функцию для получения категории
import "../css/AdminProductList.css"; // Используем обычный CSS файл

const AdminProductList = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // Храним отфильтрованные товары
  const [loading, setLoading] = useState(true); // Состояние для отслеживания загрузки
  const [currentPage, setCurrentPage] = useState(1); // Текущая страница
  const [itemsPerPage] = useState(10); // Количество товаров на странице
  const [totalProducts, setTotalProducts] = useState(0); // Общее количество товаров
  const [searchQuery, setSearchQuery] = useState(""); // Строка поиска

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fetchedProducts = await getProducts();
        setTotalProducts(fetchedProducts.length); // Сохраняем общее количество товаров

        // Применяем фильтрацию по поисковому запросу
        const filtered = fetchedProducts.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Загружаем только те товары, которые соответствуют текущей странице
        const indexOfLastProduct = currentPage * itemsPerPage;
        const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
        const currentPageProducts = filtered.slice(
          indexOfFirstProduct,
          indexOfLastProduct
        );

        // Загружаем категорию для каждого товара на текущей странице
        const productsWithCategory = await Promise.all(
          currentPageProducts.map(async (product) => {
            const category = await getCategoryByProductId(product._id);
            return {
              ...product,
              category: category ? category.name : "Unknown",
            };
          })
        );

        setFilteredProducts(filtered); // Сохраняем все отфильтрованные товары
        setProducts(productsWithCategory); // Товары с категориями для текущей страницы
        setLoading(false); // После загрузки данных, меняем состояние на false
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false); // В случае ошибки, тоже меняем состояние на false
      }
    };

    fetchProducts();
  }, [currentPage, searchQuery]); // Эффект сработает, если текущая страница или строка поиска изменятся

  const handleDelete = async (id) => {
    try {
      await deleteProduct(id);
      setProducts(products.filter((product) => product._id !== id));
      setFilteredProducts(
        filteredProducts.filter((product) => product._id !== id)
      ); // Удаляем из отфильтрованных
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  // Количество страниц для пагинации на основе отфильтрованных товаров
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Функция для переключения страницы
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Функция для обработки ввода поиска
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // При изменении поиска сбрасываем на первую страницу
  };

  if (loading) {
    return (
      <div className="loadingContainer">
        <h2>Загрузка...</h2>
      </div>
    );
  }

  return (
    <div className="adminProductListContainer">
      <h1 className="adminProductListHeading">Продукты</h1>
      {/* Поле поиска */}
      <div className="inputContainer">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Поиск по названию продукта"
          className="input"
        />
      </div>
      <Link to="/manage-products/new" className="linkAccent">
        Добавить продукт
      </Link>
      Всего: {filteredProducts.length}
      <ul className="adminProductList">
        {products.map((product) => (
          <li key={product._id} className="adminProductListItem">
            <img
              className="productImage"
              src={product.images[0]}
              alt={product.name}
            />
            <div>
              <h3>{product.name}</h3>
              <p>Категория: {product.category}</p>
            </div>
            <div>
              <Link
                to={`/manage-products/edit/${product._id}`}
                className="linkAccent"
              >
                Изменить
              </Link>
              <button
                onClick={() => handleDelete(product._id)}
                className="delete"
              >
                Удалить
              </button>
            </div>
          </li>
        ))}
      </ul>
      {/* Пагинация */}
      <div className="pagination">
        {pageNumbers.length > 1 &&
          pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={`pageButton ${currentPage === number ? "active" : ""}`}
            >
              {number}
            </button>
          ))}
      </div>
    </div>
  );
};

export default AdminProductList;
