import axios from "axios";

const API_URL = "https://crosshack.store/api/products"; // Укажите ваш базовый URL

export const getProducts = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const getProduct = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  return response.data;
};

export const getCategoryByProductId = async (productId) => {
  try {
    const response = await axios.get(`${API_URL}/${productId}/category`);
    return response.data;
  } catch (error) {
    console.error("Error fetching category:", error);
    return null;
  }
};

export const getProductByCategoryAndName = async (
  categoryName,
  productName
) => {
  try {
    const url = `${API_URL}/productByCategoryAndName/${encodeURIComponent(
      categoryName
    )}/${encodeURIComponent(productName)}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching product by category and name:", error);
    throw error;
  }
};

export const getProductsByCategoryName = async (categoryName) => {
  const response = await axios.get(`${API_URL}/category/${categoryName}`);
  return response.data;
};

export const getCategories = async () => {
  const response = await axios.get("http://localhost:5000/api/categories");
  return response.data;
};

export const addProduct = async (product) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(API_URL, product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updateProduct = async (id, product) => {
  const token = localStorage.getItem("token");
  const response = await axios.put(`${API_URL}/${id}`, product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const deleteProduct = async (id) => {
  const token = localStorage.getItem("token");
  const response = await axios.delete(`${API_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Запросить код подтверждения для email пользователя
export const requestVerificationCode = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/request-code`, { email });
    return response.data.message;
  } catch (error) {
    throw new Error(
      error.response?.data?.error || "Ошибка при запросе кода подтверждения"
    );
  }
};

// Проверить код для заданного email
export const verifyCode = async (email, code) => {
  try {
    const response = await axios.post(`${API_URL}/verify-code`, {
      email,
      code,
    });
    return response.data.message;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Ошибка при проверке кода");
  }
};

// Добавить отзыв к продукту, передавая комментарий и рейтинг отдельно
export const addReview = async (productId, email, code, comment, rating) => {
  try {
    console.log("Отправка отзыва:", {
      productId,
      email,
      code,
      comment,
      rating,
    });
    const response = await axios.post(`${API_URL}/${productId}/reviews`, {
      email,
      code,
      comment,
      rating,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Ошибка при отправке отзыва:",
      error.response?.data?.error || error.message
    );
    throw new Error(
      error.response?.data?.error || "Ошибка при добавлении отзыва"
    );
  }
};

// Получить все отзывы для продукта по его ID
export const getProductReviews = async (productId) => {
  try {
    const response = await axios.get(`${API_URL}/${productId}/reviews`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.error || "Ошибка при получении отзывов"
    );
  }
};
