import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCategories, deleteCategory } from "../services/categoryService";
import "../css/AdminCategoryList.css"; // Используем CSS Module

const AdminCategoryList = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id);
      setCategories(categories.filter((category) => category._id !== id));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <div className="adminCategoryListContainer">
      <h1 className="adminCategoryListHeading">Категории</h1>
      <Link to="/manage-categories/new" className="linkAccent">
        Добавить категорию
      </Link>
      Всего: {categories.length}
      <ul className="adminCategoryList">
        {categories.map((category) => (
          <li key={category._id} className="adminCategoryListItem">
            <img className="categoryImage" src={category.imageLink} />
            {category.name}
            <div>
              <Link
                to={`/manage-categories/edit/${category._id}`}
                className="linkAccent"
              >
                Изменить
              </Link>
              <button
                onClick={() => handleDelete(category._id)}
                className="delete"
              >
                Удалить
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminCategoryList;
