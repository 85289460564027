// LoginPage.js
import React, { useState } from "react";
import "../css/LoginPage.css";
const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://crosshack.store/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        onLogin(data.token);
        // Redirect to home page after successful login
        window.location.href = "/";
      } else {
        setError(data.msg); // Set error message if login failed
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Login failed. Please try again."); // Generic error message
    }
  };

  return (
    <div className="auth-form-container">
      <h2>Войти</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div className="text">
            <p className="login">Login</p>
            <p className="mandatory">Обязательное поле</p>
          </div>
          <div className="login-container">
            <input
              type="text"
              placeholder="Login"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input"
            />
          </div>
        </div>
        <br />
        <div className="input-group">
          <div className="text">
            <p className="password">Password</p>
            <p className="mandatory">Обязательное поле</p>
          </div>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="password-input"
            />
            <button
              type="button"
              className="toggle-password-visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Скрыть" : "Показать"}
            </button>
          </div>
        </div>
        <div className="login-error">
          <button type="submit" className="auth-button">
            Войти
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
