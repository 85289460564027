import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { jwtDecode } from "jwt-decode";
import ProtectedRoute from "./components/ProtectedRoute";
import Navbar from "./components/Navbar";
import HomePage from "./components/HomePage";
import LoginPage from "./components/LoginPage";
import AdminPanel from "./components/AdminPanel";
import AdminProductList from "./components/AdminProductList";
import NotFound from "./components/NotFound";
import { registerUser } from "./services/authService";
import AdminCategoryList from "./components/AdminCategoryList";
import AdminCategoryForm from "./components/AdminCategoryForm";
import AdminProductForm from "./components/AdminProductForm";
import UsersList from "./components/UsersList";
import RedeemLicense from "./components/RedeemLicense";
import ResetHWID from "./components/ResetHWID";
import FindUser from "./components/FindUser";
import GenerateKey from "./components/GenerateKey";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const navbarRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  }, [navbarRef]);

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.user.isAdmin) {
          setIsAdmin(true); // Если это администратор
        } else {
          setIsAdmin(false); // Если не администратор
          localStorage.removeItem("token"); // Удаляем токен, если пользователь не администратор
          setToken(null); // Обнуляем состояние токена
        }
      } catch (err) {
        console.error("Invalid token:", err);
        setToken(null);
        localStorage.removeItem("token");
      }
    }
  }, [token]);

  const handleLogin = (newToken) => {
    try {
      const decodedToken = jwtDecode(newToken);
      if (decodedToken.user.isAdmin) {
        localStorage.setItem("token", newToken);
        setToken(newToken);
        setIsAdmin(true);
      } else {
        setError("Access denied: Admin rights are required.");
        setToken(null);
        localStorage.removeItem("token");
      }
    } catch (err) {
      console.error("Invalid token:", err);
      setError("Invalid token.");
      setToken(null);
      localStorage.removeItem("token");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    setIsAdmin(false);
  };

  const handleRegister = async (formData) => {
    try {
      const response = await registerUser(formData);
      const newToken = response.token;
      const decodedToken = jwtDecode(newToken);
      if (decodedToken.user.isAdmin) {
        localStorage.setItem("token", newToken);
        setToken(newToken);
        setIsAdmin(true);
        setError("");
      } else {
        setError("Access denied: Admin rights are required.");
        setToken(null);
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.error("Registration error:", error);
      setError("Registration failed. Please try again.");
    }
  };

  return (
    <div className="App">
      <HelmetProvider>
        <Router>
          <div className="page">
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="container" style={{ paddingTop: navbarHeight }}>
              <Routes>
                {/* Если токена нет, перенаправляем на страницу логина */}
                <Route
                  path="/"
                  element={token ? <HomePage /> : <Navigate to="/login" />}
                />
                <Route
                  path="/login"
                  element={<LoginPage onLogin={handleLogin} />}
                />

                {/* Защищенные маршруты для администраторов */}
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <AdminPanel />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/find-user"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <FindUser />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/generate-key"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <GenerateKey />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reset-hwid"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <ResetHWID />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/redeem-license"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <RedeemLicense />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/show-users"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <UsersList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-products"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <AdminProductList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-categories/edit/:id"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <AdminCategoryForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-categories/new"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <AdminCategoryForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-categories"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <AdminCategoryList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-products/edit/:id"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <AdminProductForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-products/new"
                  element={
                    <ProtectedRoute token={token} isAdmin={isAdmin}>
                      <AdminProductForm />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </Router>
      </HelmetProvider>
    </div>
  );
};

export default App;
