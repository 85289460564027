// components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Время в секундах
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Invalid token:", error);
    return true;
  }
};

const ProtectedRoute = ({ token, children }) => {
  if (!token || isTokenExpired(token)) {
    localStorage.removeItem("token"); // Удаляем истекший токен
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
