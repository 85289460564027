import React from "react";
import styles from "../css/HomePage.module.css";
import AdminControlPanel from "./AdminKeyControl";
import AdmineSiteControl from "./AdmineSiteControl";
const HomePage = ({ categories }) => {
  return (
    <div className={styles.homePage}>
      <div className={styles.mainContent}>
        <AdminControlPanel />
        <AdmineSiteControl />
      </div>
    </div>
  );
};

export default HomePage;
