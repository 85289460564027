// src/components/RedeemLicense.js
import React, { useState } from "react";
import axios from "axios";

const RedeemLicense = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hwid, setHwid] = useState("");
  const [license, setLicense] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/post`,
        {
          command: "redeem",
          username,
          password,
          hwid,
          license,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setResponse(res.data);
    } catch (error) {
      setResponse(error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <h2>Redeem License</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <label>
          HWID:
          <input
            type="text"
            value={hwid}
            onChange={(e) => setHwid(e.target.value)}
          />
        </label>
        <br />
        <label>
          License:
          <input
            type="text"
            value={license}
            onChange={(e) => setLicense(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Redeem</button>
      </form>
      <pre>{JSON.stringify(response, null, 2)}</pre>
    </div>
  );
};

export default RedeemLicense;
