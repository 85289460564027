// src/components/FindUser.js
import React, { useState } from "react";
import axios from "axios";

const FindUser = () => {
  const [entry, setEntry] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin`,
        {
          command: "find",
          entry,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      setResponse(res.data);
    } catch (error) {
      setResponse(error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <h2>Find User</h2>
      <form onSubmit={handleSubmit}>
        <label>
          UUID4 License:
          <input
            type="text"
            value={entry}
            onChange={(e) => setEntry(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Find</button>
      </form>
      <pre>{JSON.stringify(response, null, 2)}</pre>
    </div>
  );
};

export default FindUser;
