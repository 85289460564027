// src/components/GenerateKey.js
import React, { useState } from "react";
import axios from "axios";
import "../css/GenerateKey.css";
const GenerateKey = () => {
  const [length, setLength] = useState(90);
  const [rank, setRank] = useState(2);
  const [quantity, setQuantity] = useState(1);
  const [response, setResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin`,
        {
          command: "generate",
          length,
          rank,
          quantity,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      setResponse(res.data);
    } catch (error) {
      setResponse(error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <h2>Generate Key</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Length:
          <input
            type="number"
            value={length}
            onChange={(e) => setLength(e.target.value)}
          />
        </label>
        <br />
        <label>
          Rank:
          <input
            type="number"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
          />
        </label>
        <br />
        <label>
          Quantity:
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Generate</button>
      </form>
      <pre>{JSON.stringify(response, null, 2)}</pre>
    </div>
  );
};

export default GenerateKey;
