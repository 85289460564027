import React from "react";
import "../css/AdmineSiteControl.css"; // Подключаем CSS для стилизации

const AdmineSiteControl = () => {
  return (
    <div className="admin-site-control">
      <h2>Управление сайтом</h2>
      <div className="link-group">
        <a href="/manage-products">Manage Products</a>
        <a href="/manage-categories">Manage Categories</a>
      </div>
    </div>
  );
};

export default AdmineSiteControl;
